













import Vue from 'vue'
import { Action } from 'vuex-class'
import { FileDto, SimpleServerResponse } from '@/store/types'
import { UPLOAD_AND_TALENT_SUGGESTION } from '@/store/employee/actions.type'
import { Component, Prop } from 'vue-property-decorator'

@Component
// Container for the file upload used to upload the kode talent results
// @group KODE
export default class FileUpload extends Vue {
  file: File | undefined = new File([''], 'filename')

  // Defines if the uploaded file is used to search for a matching talent. If not, it is just for saving the results file
  @Prop({ required: true })
  search: boolean

  @Action(UPLOAD_AND_TALENT_SUGGESTION)
  public uploadAndSuggestTalents: (params: FileDto) => Promise<SimpleServerResponse>

  isLoading = false
  searchResult = []
  uploaded = false
  showHint = false
  error = {}
  // converts to base 64 content
  reader (file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (reader.result) {
          // removes metadata at the beginning
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          resolve(encoded)
        }
      }
      reader.onerror = error => reject(error)
    })
  }

  uploadFile (file: File): void {
    if (file) {
      this.isLoading = true
      this.$emit('loading', this.isLoading)
      this.reader(file).then(result => {
        const fileDt: FileDto = {
          base64Content: result as string,
          filename: file.name
        }
        return this.uploadAndSuggestTalents(fileDt).then(data => {
          this.searchResult = data.content
          if (data.content.length === 0) {
            this.showHint = data.content.length === 0
            this.$emit('showhint', this.showHint)
          }
          this.uploaded = true
          this.$emit('uploaded', this.uploaded)
          if (data.message !== 'SUCCESS') {
            this.$emit('chapters', this.$i18n.t('file-upload.missing-chapter').toString() + ' ' + data.message.replace(/^\[(.+)\]$/, '$1').replace(/,/g, '').split(' ').map(competences => this.$i18n.t('file-upload.chapters.' + competences).toString()).join(', '))
          }
        }).catch(error => {
          this.$root.$emit('alert', this.$i18n.t('kode-score-administration.' + error.message).toString(), '', true)
        }
        )
      }).finally(() => {
        this.isLoading = false
        this.$emit('upload', this.searchResult)
        this.$emit('loading', this.isLoading)
      }).catch(reject => {
        this.$root.$emit('alert', this.$i18n.t('kode-score-administration.' + reject.message).toString(), '', true)
      })
    } else {
      this.$root.$emit(
        'alert',
        this.$i18n.t('kode-score-administration.file-upload-failed').toString()
      )
    }
  }

  ifCheckUpload (): void {
    if (this.search) {
      if (this.file !== undefined) {
        this.uploadFile(this.file)
      }
    }
  }

  fileChange (file: File): void {
    this.$emit('filechange', file)
  }
}
