














































import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'

@Component
/*
@group NOT USED
 */
export default class ConfirmationOverlay extends Vue {
  @Prop()
  show: boolean

  @Prop()
  title: string

  @Prop()
  captionOk: string

  @Prop()
  captionCancel: string
}
