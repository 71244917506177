import { render, staticRenderFns } from "./ConfirmationOverlay.vue?vue&type=template&id=1f2aa4a8&scoped=true&"
import script from "./ConfirmationOverlay.vue?vue&type=script&lang=ts&"
export * from "./ConfirmationOverlay.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2aa4a8",
  null
  
)

export default component.exports