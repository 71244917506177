
































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { Action } from 'vuex-class'
import { SEARCH_TALENT_BY_NAME } from '@/store/employee/actions.type'
import { SimpleServerResponse, TableFields, TalentProfile } from '@/store/types'
import CompetenceProfileModal from '@/components/forms/CompetenceProfileModal.vue'
import ResultsPage from '@/components/search-results/ResultsPage.vue'
import SearchResult from '@/components/search-results/SearchResult.vue'
import FileUpload from '@/components/kode/FileUpload.vue'
import { SortingInfo } from '@/store/commonFormInput/types'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'
import { parseDateToOutputDate } from '@/common/globals'
import { hasRight } from '@/utils/rights'
import Rights from '@/common/rights'
@Component({
  components: { FileUpload, SearchResult, ResultsPage, CompetenceProfileModal, LoadingSpinner }
})
export default class KodeScore extends Vue {
  @Action(SEARCH_TALENT_BY_NAME)
  public searchTalentByName: (params: string) => Promise<SimpleServerResponse>

  searchQuery: string | null = null
  searchResult = []
  profile: TalentProfile = { firstname: '', lastname: '', email: '', profileId: '', kodeStatus: '' }
  private isLoading = false
  private showHint = false
  private uploaded = false
  parentFile: File | undefined = new File([''], 'filename')
  warningMessage = ''

  maxPerColumn = 1

  get sortable (): SortingInfo[] {
    return [
      { key: 'firstname', label: this.$i18n.t('personal-information.firstName').toString() },
      { key: 'lastname', label: this.$i18n.t('personal-information.lastName').toString(), sort: true },
      { key: 'email', label: this.$i18n.t('personal-information.email').toString() }
    ]
  }

  upload (ev: any): void {
    this.searchResult = ev
    if (this.searchResult.length === 0) {
      this.warningMessage = this.$i18n.t('info.no-results').toString()
    }
  }

  search (): void {
    if (this.searchQuery) {
      this.isLoading = true
      this.searchTalentByName(this.searchQuery).then(data => {
        this.searchResult = data.content
        this.showHint = data.content.length === 0
      }).finally(() => {
        this.isLoading = false
        this.uploaded = false
      })
    } else {
      this.searchResult = []
    }
  }

  openCompetenceProfileModal (profile: TalentProfile): void {
    this.profile = profile
    this.$bvModal.show('talent-competence-profile-modal')
  }

  color (finish: boolean, row: number): string {
    if (finish && row !== 3) return 'grey'
    else return 'white'
  }

  parseDateToOutputDate (date: Date): string | undefined {
    return parseDateToOutputDate(date, this.$i18n.locale)
  }

  get hasCreateRight (): boolean {
    return hasRight(Rights.STUDENT_COMPETENCE_PROFILE_CREATE)
  }

  get hasUpdateRight (): boolean {
    return hasRight(Rights.STUDENT_COMPETENCE_PROFILE_UPDATE)
  }

  get searchResultFields (): TableFields[] {
    return [
      {
        key: 'firstname',
        label: this.$t('personal-information.firstName').toString(),
        sortable: true
      },
      {
        key: 'lastname',
        label: this.$t('personal-information.lastName').toString(),
        sortable: true
      },
      {
        key: 'email',
        label: this.$t('personal-information.email').toString(),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('actions.action').toString(),
        sortable: false
      }
    ]
  }
}
