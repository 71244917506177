

























































































































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { CompetenceProfileType, SimpleServerResponse, TableFields, TalentProfile } from '@/store/types'
import { Action } from 'vuex-class'
import {
  GET_READ_OUT_COMPETENCES_FOR_TALENT,
  GET_STUDENT_COMPETENCE_PROFILE,
  SET_STUDENT_COMPETENCE_PROFILE,
  UPLOAD_PDF_AND_GET_COMPETENCES
} from '@/store/employee/actions.type'
import CompetenceProfileValueField from '@/components/forms/CompetenceProfileValueField.vue'
import SubmitSuccess from '@/components/forms/SubmitSuccess.vue'
import CompetenceProfile from '@/components/kode/CompetenceProfile.vue'
import ConfirmationOverlay from '@/components/common/ConfirmationOverlay.vue'
import FileUpload from '@/components/kode/FileUpload.vue'
import { TalentFile, TargetFile } from '@/store/employee/types'
import LoadingSpinner from '@/components/common/LoadingSpinner.vue'

@Component({
  components: { LoadingSpinner, ConfirmationOverlay, CompetenceProfile, SubmitSuccess, CompetenceProfileValueField, FileUpload }
})
/*
Only ever used in the KodeScore page to make it possible to save KODE-data to talent profiles.
@group FORMS
 */
export default class CompetenceProfileModal extends Vue {
  // the talent profile to add competence / KODE data to
  @Prop({ required: true })
  profile: TalentProfile

  // if a file is uploaded or not
  @Prop({ required: true })
  uploaded: boolean

  // the KODE pdf document
  @Prop({ required: false })
  parentFile: File

  usedProfile: TalentProfile

  @Action(GET_STUDENT_COMPETENCE_PROFILE)
  public getStudentCompetenceProfile: (params: string) => Promise<SimpleServerResponse>

  @Action(SET_STUDENT_COMPETENCE_PROFILE)
  public setStudentCompetenceProfile: (params: CompetenceProfileType) => Promise<SimpleServerResponse>

  @Action(GET_READ_OUT_COMPETENCES_FOR_TALENT)
  public getReadOutCompetencesForTalent: (params: TargetFile) => Promise<SimpleServerResponse>

  @Action(UPLOAD_PDF_AND_GET_COMPETENCES)
  public uploadPDFAndGetCompetences: (params: TalentFile) => Promise<SimpleServerResponse>

  competenceProfile: CompetenceProfileType = {}
  loading = true
  showWaitingText = false
  requestFailed = false
  error = {}
  uploadError = false
  wrongData = false
  file: File | undefined = new File([''], 'filename')
  warningMessage = ''

  private isLoading = false
  searchResult = []
  private savePDF = false
  private internalUploaded = false
  targetFile: TargetFile = { filename: '', profileId: '' }
  talentFile: TalentFile = { profileId: '', fileDTO: { base64Content: '', filename: '' } }

  get searchResultFields (): TableFields[] {
    return [
      {
        key: 'firstname',
        label: this.$t('personal-information.firstName').toString(),
        sortable: true
      },
      {
        key: 'lastname',
        label: this.$t('personal-information.lastName').toString(),
        sortable: true
      },
      {
        key: 'email',
        label: this.$t('personal-information.email').toString(),
        sortable: true
      },
      {
        key: 'actions',
        label: this.$t('actions.action').toString(),
        sortable: false
      }
    ]
  }

  originalCompetenceProfile: CompetenceProfileType = {}

  loadReadOutData (usedProfile: TalentProfile): void {
    if (this.file) {
      this.targetFile.profileId = usedProfile.profileId
      this.targetFile.filename = this.file.name
      this.isLoading = true
      this.getReadOutCompetencesForTalent(this.targetFile).then(data => {
        this.competenceProfile = { ...data.content }
        this.originalCompetenceProfile = { ...data.content }
        this.usedProfile = usedProfile
      }).catch(error => {
        if (error.message === 'Problems occurred while getting data from file') {
          this.requestFailed = false
          this.competenceProfile = { profileStudent: usedProfile.profileId }
        } else {
          this.error = error
          this.requestFailed = true
        }
      }).finally(() => {
        this.searchResult = []
        this.internalUploaded = false
        this.savePDF = true
        this.isLoading = false
      })
    }
  }

  showCompetenceProfile (): void {
    this.loading = true
    this.getStudentCompetenceProfile(this.usedProfile.profileId).then(data => {
      this.competenceProfile = { ...data.content }
      this.originalCompetenceProfile = { ...data.content }
    }).catch(error => {
      if (error.message === 'Talent has no competence profile') {
        this.requestFailed = false
        this.competenceProfile = { profileStudent: this.usedProfile.profileId }
      } else {
        this.error = error
        this.requestFailed = true
      }
    }).finally(() => {
      this.loading = false
    })
  }

  saveCompetenceProfile (): void {
    if (this.savePDF) {
      this.competenceProfile.filename = this.file?.name
    }
    this.showWaitingText = true
    this.setStudentCompetenceProfile(this.competenceProfile).then(() => {
      this.originalCompetenceProfile = { ...this.competenceProfile }
      this.$emit('update')
      this.closeModal()
    }).catch(error => {
      if (error.message === 'values do not satisfy KODE constraints') {
        this.requestFailed = false
        this.wrongData = true
      } else {
        this.error = error
        this.requestFailed = true
      }
    }).finally(() => {
      this.showWaitingText = false
    })
  }

  checkForChanges (): void {
    this.$bvModal.hide('talent-competence-profile-modal')
  }

  reader (file: File) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        if (reader.result) {
          // removes metadata at the beginning
          let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
          if ((encoded.length % 4) > 0) {
            encoded += '='.repeat(4 - (encoded.length % 4))
          }
          resolve(encoded)
        }
      }
      reader.onerror = error => reject(error)
    })
  }

  uploadModal (file: File): void {
    this.uploadError = false
    this.warningMessage = ''
    this.showWaitingText = true
    this.talentFile.profileId = this.usedProfile.profileId
    this.reader(file).then(result => {
      this.talentFile.fileDTO = {
        base64Content: result as string,
        filename: file.name
      }
      return this.uploadPDFAndGetCompetences(this.talentFile).then(data => {
        this.competenceProfile = data.content
        if (data.message !== 'SUCCESS') {
          this.warningMessage = this.$i18n.t('file-upload.missing-chapter').toString() + ' ' + data.message.replace(/^\[(.+)\]$/, '$1').replace(/,/g, '').split(' ').map(competences => this.$i18n.t('file-upload.chapters.' + competences).toString()).join(', ')
        }
      }).catch(error => {
        this.uploadError = true
        this.error = error
      }
      )
    }).finally(() => {
      this.savePDF = true
      this.showWaitingText = false
    })
  }

  closeModal (): void {
    this.$root.$emit('bv::hide::modal', 'talent-competence-profile-modal')
    this.$bvModal.hide('talent-competence-profile-modal')
    this.searchResult = []
    this.internalUploaded = false
  }

  created (): void {
    this.usedProfile = this.profile
  }

  mounted (): void {
    // get talent competence profile when modal is opened
    this.$root.$on('bv::modal::shown', (bvEvent: never, modalId: string) => {
      if (modalId === 'talent-competence-profile-modal') {
        this.usedProfile = this.profile
        if (this.uploaded) {
          this.file = this.parentFile
          this.loadReadOutData(this.profile)
        }
        this.showCompetenceProfile()
      }
    })

    // set variables back when modal is closed
    this.$root.$on('bv::modal::hidden', (bvEvent: never, modalId: string) => {
      if (modalId === 'talent-competence-profile-modal') {
        this.loading = true
        this.error = ''
        this.competenceProfile = {}
        this.requestFailed = false
        this.wrongData = false
        this.internalUploaded = false
        this.savePDF = false
      }
    })
  }
}
